// ** Icon imports
import HomeIcon from 'mdi-material-ui/Home'
import TaskIcon from '@mui/icons-material/Task';
import LockIcon from '@mui/icons-material/Lock';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import HelpIcon from '@mui/icons-material/Help';
import CategoryIcon from '@mui/icons-material/Category';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PublicIcon from '@mui/icons-material/Public';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
const navigation = () => {
  return [
    {
      title: 'Dashboard',
      icon: HomeIcon,
      path: '/dashboard'
    },
    {
      sectionTitle: "Website Settings",
    },
    {
      title: 'Website',
      icon: PublicIcon,
      children: [
        {
          title: 'Privacy Policy',
          icon: LockIcon,
          path: '/privacy-policy'
        },
        {
          title: 'Terms and Conditions',
          icon: TaskIcon,
          path: '/terms-conditions'
        },
        // {
        //   title: 'Testimonials',
        //   icon: FormatQuoteIcon,
        //   path: '/testimonials'
        // },
        {
          title: 'Contact Inquiry',
          icon: ContactPageIcon,
          path: '/contact-us'
        },
        {
          title: 'FAQS',
          icon: HelpIcon,
          path: '/faqs'
        },
        {
          title: 'Blog',
          icon: ArticleIcon,
          path: '/blog'
        },
      ]
    },
    {
      sectionTitle: 'Knowledge Base'
    },
    {
      title: 'Users List',
      icon: PersonIcon,
      path: '/users'
    },
    {
      title: 'Rug List',
      icon: PersonIcon,
      path: '/rugs'
    },
    {
      title: 'Shipping Cost',
      icon: PriceCheckIcon,
      path: '/shipping-cost'
    },
    {
      title: 'Collection',
      icon: CategoryIcon,
      path: '/collection'
    },
    // {
    //   title: 'Auction Management',
    //   icon: SupervisedUserCircleIcon,
    //   //path: '/auction-management'
    // },
    {
      title:'Notifications',
      icon: NotificationsOutlinedIcon,
      path: '/notifications'
    },
    {
      title: 'Payment History',
      icon: AttachMoneyIcon,
      path: '/payment-history'
    },

  ]
}

export default navigation
